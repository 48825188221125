import React, { useState, useEffect } from "react";
import "./heading.css";
import AuthService from "../../services/auth.service";
import Logout from "../Logout";

function Heading() {

    const [loggedIn, setLoggedIn] = useState(false);

    // const logOut = () => {
    //     AuthService.logout();
    // };

    useEffect(() => {
        const user = AuthService.getCurrentUser();
    
        console.log("The User is: "+user);
    
        if (user) {
          setLoggedIn(true);
        }
      
    }, []);



    return (
        <div>
            <header>
                <h1>Feedback Dashboard</h1>
                <div className="headerMenu">

                    {loggedIn ? <Logout />  : '' }
                    
                </div>
            </header>
            
        </div>
    );
}


export default Heading;