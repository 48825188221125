import React from "react";
import AuthService from "../services/auth.service";

function Logout() {

    const logOut = () => {
        AuthService.logout();
    };
    
    return (
        <a href="/login" className="nav-link" onClick={logOut}> LogOut </a>
    );
}

export default Logout;