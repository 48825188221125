import React, { useState, useEffect } from 'react';
import FeedbackEntry from './FeedbackEntry';
import authHeader from '../../services/auth-header';
import "./feedbackList.css";

function FeedbackList() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectionList, setSelectionList] = useState([]);
  const [counts, setCounts] = useState(null);
  const [isCheckAll, setIsCheckAll] = useState(false);


  function createFeedbackEntry(feedbackDetails){
    return <FeedbackEntry
      key={feedbackDetails.Feedback_ID}
      fid={feedbackDetails.Feedback_ID} 
      dateTime={feedbackDetails.DT_Created}
      message={feedbackDetails.Message}
      email={feedbackDetails.User_Email}
      passSelectionList={setSelectionList}
      selectionList={selectionList}
    />
    
  }
  
  function handleSelectAll(e){
    setIsCheckAll(!isCheckAll);
  }


  function HandleDeleteFeedback(){
    //send post request
    const url = process.env.REACT_APP_BACKEND_URL + '/api/feedback/delete';
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 
                  'x-access-token': authHeader()['x-access-token'] },
      body: JSON.stringify(selectionList)
    };

    const sendRequest = async () => {
      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        // console.log(data);

        //reload the list of feedback
        getData();
        setSelectionList([]);

      } catch (error) {
        console.log(error);
      }
    };
    
    sendRequest();     
  }
 
  function HandleSaveFeedback(){
    //send post request
    const url = process.env.REACT_APP_BACKEND_URL + '/api/feedback/save';
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 
                  'x-access-token': authHeader()['x-access-token'] },
      body: JSON.stringify(selectionList)
    };

    const sendRequest = async () => {
      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        // console.log(data);

        //reload the list of feedback
        getData();
        setSelectionList([]);

      } catch (error) {
        console.log(error);
      }
    };
    
    sendRequest();     
  }

  //fetch feedback entries
  const getData = async () => {
    try {
      
      // let headers = authHeader(); 
      // console.log(headers);

      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/feedback', { 
          headers: authHeader() });

      if (!response.ok) {
        throw new Error("Error: The status is " + response.status + " " + response.statusText);
        // console.log(response);
      }
      let actualData = await response.json();
      setData(actualData);
      setError(null);
      
      // console.log(actualData);
        
    } catch(err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
      getCounts();
    }  
  }

  //fetch stats
  const getCounts = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/feedback/counts', { 
        headers: authHeader() });
      if (!response.ok) {
        throw new Error("Error: The status is " + response.status + " " + response.statusText);
      }
      let actualCounts = await response.json();
      setCounts(actualCounts);
        
    } catch(err) {

    } finally {

    }  
  }

  
  useEffect(() => {
    getData();
  }, [])
  
  // console.log(data);
  
  return (
        <div className="feedbackListContainer">
          <div className='feedbackListContainerHead flex-container'>
            <div className='flex-child'> 
              <input onChange={handleSelectAll} name="selectAll" type="checkbox" />    
            </div>
            
            <div className='flex-child'> 
              <button className='deleteBtn' onClick={HandleDeleteFeedback}>Delete Selected</button>
            </div>
            <div className='flex-child'> 
              <button className='saveBtn' onClick={HandleSaveFeedback}>Save Selected</button>
            </div>
            <div className='flex-child'> 
              {counts && (<span> outstanding: {counts[0].Outstanding}, deleted: {counts[0].Deleted} </span>)}
            </div>
          </div>
          
          {loading && (<div>A moment please...</div>)}
          {error && (<div>{"There is a problem fetching the post data - " + error}</div>)}
          {data && data.map(createFeedbackEntry)}
      </div>
  )
}

export default FeedbackList;





