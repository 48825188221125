import React, {useState} from "react";

function FeedbackEntry(props) {

  const [readMore, setReadMore] = useState(false);
  
  let message = props.message;
  let shortMessage = message.substring(0,300);

  function CheckboxChange(e){
    let value = e.target.value;
    let feedbackList = props.selectionList; //list of feedback items to be deleted / changed

    if(feedbackList.includes(value))
    {
      const index = feedbackList.indexOf(value);
      if (index > -1) { // only splice array when item is found
        feedbackList.splice(index, 1); // 2nd parameter means remove one item only
      }
     
    }
    else{
      feedbackList.push(value);
    }
    console.log(feedbackList);
    props.passSelectionList(feedbackList);
  }
  
  var checkboxName = "chk[" + props.fid + "]";
  return (
       
    <div id={props.fid} className="feedbackEntryContainer">
      
      <input onChange={CheckboxChange} name={checkboxName} value={props.fid} type="checkbox" />
      <p>
        {props.email}
        {props.dateTime}
      </p>
      <p>
        
        {readMore ? message : shortMessage }
        {/* {props.message} */}

      </p>
      <button className="readMoreBtn" onClick={() => setReadMore(!readMore)}>
        {readMore ? "<<< show less" : "show more >>>"}
      </button>    
    </div>
 
  );
}

export default FeedbackEntry;