import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";

import Footer from './components/Footer/Footer';
import Heading from './components/Header/Heading';
import FeedbackList from "./components/Feedback/FeedbackList";
import Login from "./components/Login/login.jsx";
import AuthService from "./services/auth.service";

// import Home from "./components/Home";
// import BoardUser from "./components/BoardUser";
// import BoardAdmin from "./components/BoardAdmin";

// import "bootstrap/dist/css/bootstrap.min.css";

function App() {

  // const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [currentUser, setCurrentUser] = useState(undefined);
  // const [user, setUser] = useState(null);
  
  const user = AuthService.getCurrentUser();

  // console.log("The User is:");
  // console.log(user);

  // if (user) {
  //   setCurrentUser(user);
  //   // setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
  // }

  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Navigate to="/login" replace />;
    }
  
    return children;
  };



  const logOut = () => {
    AuthService.logout();
  };



  return (
    
    <div>
    <Heading /> 
    <Routes>

      <Route 
          path='/' 
          element={ 
          
            <ProtectedRoute user={user}> 
              <FeedbackList /> 
            </ProtectedRoute>
            
          } />

      <Route 
        path='/feedback' 
        element={ 
        
          <ProtectedRoute user={user}> 
            <FeedbackList /> 
          </ProtectedRoute>
          
        } />
      
      
      <Route path='/login' element={ <Login /> }/>
       
    </Routes>
    <Footer />
    </div>
  );

}

export default App;
